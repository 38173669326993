import { AppProps } from "next/app";
import { ChakraProvider } from "@chakra-ui/react";
import Script from "next/script";
import "./styles.css";
import { DefaultSeo } from "next-seo";
import { useEffect } from "react";

function MyApp({ Component, pageProps }: AppProps) {
	useEffect(() => {
		import('preline')
	}, [])

	return (
		<ChakraProvider>
			<Script
				defer
				data-domain="supersupport.ai"
				src="/events/js/script.outbound-links.js"
				data-api="/events/api/event"
			/>
			<DefaultSeo
				titleTemplate="%s | SuperSupport.ai"
				description={"Bringing the power of ChatGPT to Zendesk to increase efficiency and customer satisfaction."}
				openGraph={{
					type: 'website',
					locale: 'en_US',
					url: 'https://supersupport.ai',
					siteName: 'SuperSupport.ai',
					images: [
						{
							url: 'https://supersupport.ai/static/images/default-og.png',
							alt: 'Powerful AI features at your support team\'s fingertips.',
							width: 2016,
							height: 938
						}
					]
				}}
				twitter={{
					site: '@supersupportai',
					handle: '@benrobertsonio',
					cardType: 'summary_large_image',
				}}
			/>
			<Component {...pageProps} />
		</ChakraProvider>
	);
}

export default MyApp;
